import { render, staticRenderFns } from "./NavBarMenu.vue?vue&type=template&id=2b8a7a5b&scoped=true"
import script from "./NavBarMenu.vue?vue&type=script&lang=js"
export * from "./NavBarMenu.vue?vue&type=script&lang=js"
import style0 from "styles/components/navbarmenu.css?vue&type=style&index=0&id=2b8a7a5b&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b8a7a5b",
  null
  
)

export default component.exports