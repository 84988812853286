<template>
  <!-- <div style="width: 1200px; margin: 0px auto; display: flex; flex-wrap: wrap; padding-bottom: 350px; padding-top: 40px"> -->
  <div style="width: 1200px; margin: 0px auto; display: flex; flex-wrap: wrap; padding-top: 40px">
    <div style="display: flex; flex-wrap: wrap">
      <!-- <div class="new_home_goods_flex">
        <img :src="adslist" alt="" style="width: 100%; height: 388px; cursor: pointer" />
      </div> -->
      <div @mouseover="mouseove(index)" @mouseout="mouseout" class="new_home_goods_flex" v-for="(item, index) in count"
        :key="index" :class="[active === index ? 'boxShaow' : '']">
        <div class="goods">
          <div v-if="!imgg" class="imgs" @click="bts(item)">
            <img :src="item.goods_images[0]" alt="" ref="imgs" />
          </div>
          <div class="new_home_goods_info_flex">
            <div class="new_home_goods_title_wrapper flex-space">
              <div class="new_home_goods_title_flex">{{ item.goods_name }}</div>
            </div>
            <div class="new_home_goods_price_flex flex-space">
              <div style="color: rgb(255, 51, 51); padding-top: 5px">
                <span style="font-size: 20px; margin-right: 2px; font-weight: 600">￥</span>
                <span style="font-size: 20px; font-weight: 600">{{ item.goods_price }}</span>
                <span class="new_home_goods_local_flex" style="margin-left: 4px">包邮</span>
              </div>
              <div style="color: rgb(122, 131, 153); padding-top: 12px;margin-left: 20px;">{{ item.goods_weight }}g/件
              </div>
            </div>
            <div class="flex-space butspace">
              <div class="new_home_goods_locals">{{ item.city }}</div>
              <!-- <div v-if="item.deliver" class="deliver"><img :src="imgs[item.deliver] || ''" alt="" /></div> -->
              <button type="button" class="btn" style="width: 80px; height: 24px; font-size: 12px; padding: 0px"><span
                  @click="bts(item)">立即购买</span></button>

            </div>

            <!-- <div class="img4">
              <img class="g" :src="item.goods_images[1]" alt="" @mouseover="get($event, index)" @mouseout="set(index)" />
              <img class="g" :src="item.goods_images[2]" alt="" @mouseover="get($event, index)" @mouseout="set(index)" />
              <img class="g" :src="item.goods_images[3]" alt="" @mouseover="get($event, index)" @mouseout="set(index)" />
              <img class="g" :src="item.goods_images[4]" alt="" @mouseover="get($event, index)" @mouseout="set(index)" />
            </div> -->
          </div>
        </div>
        <div style="display: flex;justify-content: flex-end;align-items: center;">
          <el-button type="primary" size="mini" @click.stop="xiazai(item)">下载素材</el-button>
        </div>
      </div>
    </div>
    <div class="block" v-if="show">
      <p>暂无更多商品</p>
      <p style="color: #3af; padding-top: 20px">严正声明</p>
      <p style="font-size: 12px; line-height: 17px; font-weight: 400; color: #7a8399"><span>1
          本站为正规礼品代发云仓平台，不提供信封、空包等违法服务，严禁用于刷单、洗钱行为！ </span><span style="padding: 0 0 0 10px">2
          严禁张冠李戴，非法使用他人个人信息用于犯罪，一经发现，直接封号，并保存记录协助公安机关调查！</span></p>
    </div>
  </div>
</template>
<script>
import { searchGs } from '../../../../network/api'
export default {
  created() {
    this.gengxin()
    // getAdsInfo({ adstag: 'goods_list' }).then((res) => {
    //   console.log(res)getAdsInfo
    //   this.adslist = res.data.adslist[0].localimg
    // })
  },
  data() {
    return {
      show: false,
      count: [],
      active: '',
      adslist: null,
      params: {
        page: 1
      },
      last_page: 0,
      total: 0,
      imgs: {
        邮政: require('./imgs/youzheng.png'),
        极兔: require('./imgs/jtsd.png'),
        顺丰: require('./imgs/sexpress.png'),
        申通: require('./imgs/sto.png'),
        圆通: require('./imgs/yto.png'),
        韵达: require('./imgs/yunda.png'),
        中通: require('./imgs/zto.png')
      },
      imgg: '',
      x: 2
    }
  },
  methods: {
    bts(item) {
      this.$emit('goLog', item)
    },
    reverseShop() {
      // 倒叙
      this.count = this.count.reverse()
    },
    set(index) {
      // 输入移出时恢复图片
      this.$refs.imgs[index].src = this.count[index].goods_images[0]
    },
    get(e, index) {
      // 保存当前索引的缩略图
      const src = e.target.src
      // 将主图替换为缩略图
      this.$refs.imgs[index].src = src
    },
    handleCurrentChange(e) {
      console.log(e)
    },
    handleSizeChange(e) {
      // console.log(e)
      // console.log(this.imgg)
    },
    mouseout(e) {
      this.active = ''
      // console.log(e)
    },
    mouseove(e) {
      this.active = e
    },
    // 点击地区仓库筛选回调
    getList(params) {
      // 触发时格式化page
      this.params.page = 1
      searchGs(params).then((res) => {
        if (res.code === 1) {
          this.count = res.data.data
          // 调用父亲方法滚动条回到顶部
          this.$parent.shuchu()
          this.total = res.data.total
          // 内容长度小于总长度隐藏底部内容
          if (this.count.length < this.total) {
            this.show = false
          }
        }
      })
    },
    // 第一次进入商品页面调用
    gengxin() {
      searchGs().then((res) => {
        if (res.code === 1) {
          this.last_page = res.data.last_page
          this.total = res.data.total
          this.count = res.data.data
        }
      })
    },
    // navList点击后父亲调用
    newList(params) {
      // Object.assign()
      params.page = this.params.page
      this.params = params
      this.getList(params)
    },
    // 上滑加载调用
    addShop() {
      // console.log(111)
      // page等于总页数返回
      if (this.params.page === this.last_page) {
        this.show = true
        return
      }
      // 长度等于总长度返回
      if (this.count.length === this.total) {
        this.show = true
        return
      }
      this.params.page++
      // console.log(this.params)
      searchGs(this.params).then((res) => {
        // console.log(res)
        if (res.code === 1) {
          try {
            this.count.push(...res.data.data)
          } catch {
            this.count.push(...Object.values(res.data.data))
          }
          // this.count.push(...res.data.data)
        }
      })
    },
    xiazai(item) {
      const { zipurl } = item
      if (zipurl.length <= 0) {
        this.$message({
          message: '暂无素材下载',
          type: 'warning'
        });
        return
      }
      location.href = 'http://lpadmin.tongtools.com' + zipurl
    }
  }
}
</script>
<style scoped lang="scss">
.new_home_goods_price_flex {
  position: absolute;
  // background-color: red;
  top: 40px;
  justify-content: space-between;
}

.butspace {
  position: absolute;
  bottom: 10px;
  // justify-content: space-between;
}

.g {
  cursor: pointer;
}

.deliver {
  width: 56px;
  height: 24px;
  border: 1px solid #dcdfe6;
}

.img4 {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.img4>img {
  width: 48px;
  height: 48px;
  border-radius: 3px;
  margin-top: 2px;
}

.block {
  font-size: 14px;
  color: rgb(153, 153, 153);
  text-align: center;
  margin: 24px;
  text-align: center;
  width: 100%;
}

img {
  width: 100%;
  height: 100%;
}

.new_home_goods_locals {
  width: 58px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  color: #262a33;
  background: #fff;
  border-radius: 2px;
  border: 1px solid #dcdfe6;
  font-size: 12px;
  padding: 0 5px;
  margin-right: 50px;
}

.new_home_goods_local_flex {
  text-align: center;
  color: #262a33;
  background: #fff;
  border-radius: 2px;
  border: 1px solid #dcdfe6;
  font-size: 12px;
}

.boxShaow {
  box-shadow: 0 0 10px 1px #ccc;
  border-radius: 6px;
}

.btn:active {
  border: 1px solid #3af;
}

.btn {
  margin: 0 25px 0 0;
  padding: 0;
  border: 1px solid transparent;
  outline: none;
  box-sizing: content-box;
  background-color: #3af;
  border: none;
  border-radius: 3px;
  color: #fff;
  font-weight: 600;
  font-size: 13px !important;
  padding: 2px !important;
  cursor: pointer;
  /* margin-top: 10px; */
}

.goods {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  box-sizing: border-box;
}

.img_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.imgx {
  width: 48px;
  height: 48px;
  border-radius: 4px;
  object-fit: cover;
  cursor: pointer;
}

.imgs {
  width: 100%;
  height: 224px;
  cursor: pointer;
}

.new_home_goods_flex {
  box-sizing: border-box;
  /* padding: 10px; */
  width: 228px;
  margin-bottom: 20px;
  margin: 0 6px;
  margin-bottom: 20px;
  height: 388px;

  background-color: #fff;
}

.img1 {
  display: block;
  width: 100%;
  height: 230px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  object-fit: cover;
}

.new_home_goods_info_flex {
  position: relative;
  height: 118px;
  padding: 10px;
}

.flex-space {
  display: flex;
  justify-content: space-between;
}

.new_home_goods_title_flex {
  width: 200px;
  text-overflow: ellipsis;
  // height: 50px;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 16px;
  margin-left: 2px;
}

.new_home_goods_local_flex {
  display: inline-block;
  padding: 2px 4px;
  line-height: 1;
}

.new_home_goods_local {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-size: 14px;
  color: rgb(112, 110, 110);
  padding: 2px 3px 2px 3px;
  background-color: #fff;
  width: 130px;
}

.flex-center {
  justify-content: center;
}

.ant-btn-primary {
  color: #fff;
  background: #3af;
  border-color: #3af;
  border-radius: 2px;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.045);
}

.ant-btn {
  outline: 0;
}
</style>
